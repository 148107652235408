import React, { useEffect } from 'react'

import { useRouter } from 'next/router'

import SEO from 'src/components/SEO'
import Loading from 'src/components/Loading'

const Home = () => {
  const router = useRouter()
  const headTitle = '아이소 데이터 분석 솔루션'
  const headDesc =
    '아이소 데이터 분석 솔루션은 머신러닝이나 딥러닝에 대한 사전지식과 코딩 능력이 없어도 누구나 쉽게 본인이 필요한 분야에 이를 응용할 수 있는 클라우드 기반의 인공지능 플랫폼입니다. (CSV, IMAGE, TEXT Dataset Auto ML)'
  const headUrl = `https://aisodata.co.kr/`

  useEffect(() => {
    if (router && router.asPath === '/') {
      router.push('/about')
    } else {
      router.push(router.asPath)
    }
  }, [])

  return (
    <div>
      <SEO url={headUrl} title={headTitle} desc={headDesc} />
      <Loading />
    </div>
  )
}

export default Home
