import React from 'react';
import Head from 'next/head';

const SEO = ({ url, title, desc }) => {
  return (
    <Head>
      <title>{title ? title : '아이소데이터 분석 솔루션'}</title>
      <meta
        name='description'
        content={desc ? desc : '아이소데이터 분석 솔루션'}
      />
      <meta
        name='keywords'
        content='aiso, 인공지능, 인공지능 서비스, AI, machine learning'
      />
      <meta property='og:url' content={url ? url : 'https://aisodata.co.kr'} />
      <meta
        property='og:title'
        content={title ? title : '인공지능 마켓 아이소'}
      />
      <meta
        property='og:description'
        content={
          desc
            ? desc
            : '이미지, 데이터 분석 등 다양한 아이소 앱을 사용해보세요.'
        }
      />
      <meta property='og:image' content='/images/header/aiso_data_logo.png' />
    </Head>
  );
};

export default SEO;
